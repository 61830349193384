import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { apiconstants } from '../constants/api.constant';
import { IResponse } from '../models/response';
import { StorageService } from './storage.service';
import { AuthService } from './auth.service';
import { IUser } from '../models/user';
import { IPlayer } from '../models/player';
import { IScout } from '../models/scout';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(
    private _http: HttpClient,
    private _storageService: StorageService,
    private _authService: AuthService,
  ) { }

  createProfile(accountId: string, params: any): Observable<any> {
    let url = apiconstants.API_CREATE_PROFILE.replace('{id}', accountId);
    return this._http.post(url, params)
      .pipe(
        map((response: IResponse) => {
          return response.message;
        })
      );
  }

  switchProfile(userId: string): Observable<any> {
    let params = {
      userId
    }
    return this._http.post(apiconstants.API_SWITCH_USER, params)
      .pipe(
        map((response: IResponse) => {
          this._storageService.store('accessToken', response.data.token);
          this._authService.$user.next(<IUser>response.data);
          return response.data;
        })
      );
  }


  getAllEnums(): Observable<any> {
    return this._http.get(apiconstants.API_GET_ENUMS).pipe(
      map((response: IResponse) => {
        return response.data;
      })
    );
  }

  getAllUserProfiles(id: string): Observable<any> {
    let url = apiconstants.API_GET_ALL_USER_PROFILES.replace('{id}', id);
    return this._http.get(url).pipe(
      map((response: IResponse) => {
        return response.data;
      })
    );
  }

  updateUserProfile(userId: string, payload: any): Observable<any> {
    let url = apiconstants.API_USER_PROFILE.replace('{id}', userId);
    return this._http.patch<IResponse>(url, payload).pipe(
      map((response: IResponse) => response.data)
    );
  }

}
